import React from 'react';

import Button from '../../shared/Button';

import {
  Header, HeaderInner,
  HeaderLeft, HeaderRight,
  SiteTitle, Username,
} from './styles';
import { Link } from 'react-router-dom';
import Routes from '../../router/routes';

const HeaderBar = (props) => (
  <Header>
    <HeaderInner>
      <HeaderLeft>
        <SiteTitle>
          <Link to={Routes.toDashboard()}>
            Aqua
          </Link>
        </SiteTitle>
      </HeaderLeft>
      <HeaderRight>
        <Username>{props.username}</Username>
        <Button onClick={() => props.logout()}>
          Logout
        </Button>
      </HeaderRight>
    </HeaderInner>
  </Header>
);

export default HeaderBar;
