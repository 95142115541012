import createSagaMiddleWare from 'redux-saga';

import authSaga from './auth';
import qcSaga from './qc';

const sagaMiddleWare = createSagaMiddleWare();

const runSagas = () => {
  sagaMiddleWare.run(authSaga);
  sagaMiddleWare.run(qcSaga);
};

export { sagaMiddleWare as sagas, runSagas };
