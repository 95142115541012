import React from 'react';

import { StyledInput } from './styles';

const Input = (props) => (
  <StyledInput type={props.type}
               disabled={props.disabled}
               value={props.value}
               placeholder={props.placeholder}
               onInput={e => props.onChange(e.target.value)} />
);

export default Input;
