import React, { Component } from 'react';

import { deviceTypesList } from '../../constants/qc';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Modal from '../../shared/Modal';
import Select from '../../shared/Select';

import { ProvisionRigForm } from './styles';

class ProvisionRigModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceType: deviceTypesList[0],
      name: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onDeviceTypeChange = this.onDeviceTypeChange.bind(this);
    this.onNameInput = this.onNameInput.bind(this);
  }

  onDeviceTypeChange(value) {
    this.setState({ deviceType: value });
  }

  onNameInput(value) {
    this.setState({ name: value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { deviceType, name } = this.state;
    this.props.onSubmit(deviceType, name);
  }

  render() {
    const disabled = this.props.provisionRigPending;
    const { deviceType, name } = this.state;
    return (
      <Modal>
        <h2>Provision QC Rig</h2>
        <ProvisionRigForm onSubmit={this.onSubmit}>
          <Select disabled={disabled}
                  value={deviceType}
                  onChange={this.onDeviceTypeChange}
                  options={deviceTypesList}
          />
          <Input type='text'
                 placeholder='Name (optional)'
                 disabled={disabled}
                 value={name}
                 onChange={this.onNameInput}
          />
          <div>
            <Button disabled={disabled}
                    onClick={() => this.props.onClose()}>
              Cancel
            </Button>
            <Button primary
                    type='submit'
                    disabled={disabled}>
              Provision
            </Button>
          </div>
        </ProvisionRigForm>
      </Modal>
    );
  }
}

export default ProvisionRigModal;
