import { connect } from 'react-redux';

import Users from './Users';
import { actions } from '../../reducers/auth';

const mapStateToProps = state => ({
  username: state.auth.username,
  users: state.auth.users,
  getAllUsersPending: state.auth.status.getAllUsersPending,
  getAllUsersSuccess: state.auth.status.getAllUsersSuccess,
  provisionUserPending: state.auth.status.provisionUserPending,
  provisionUserSuccess: state.auth.status.provisionUserSuccess,
  updateUserPasswordPending: state.auth.status.updateUserPasswordPending,
  updateUserPasswordSuccess: state.auth.status.updateUserPasswordSuccess,
  deleteUserPending: state.auth.status.deleteUserPending,
  deleteUserSuccess: state.auth.status.deleteUserSuccess,
});

const mapDispatchToProps = dispatch => ({
  getAllUsers: () => dispatch(
    actions.getAllUsersRequest()
  ),
  provisionUser: (username, password) => dispatch(
    actions.provisionUserRequest(username, password)
  ),
  updateUserPassword: (username, password) => dispatch(
    actions.updateUserPasswordRequest(username, password)
  ),
  deleteUser: (username) => dispatch(
    actions.deleteUserRequest(username)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
