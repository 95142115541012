import { connect } from 'react-redux';

import Rigs from './Rigs';
import { actions } from '../../reducers/qc';

const mapStateToProps = state => ({
  rigs: state.qc.rigs,
  getAllRigsPending: state.qc.status.getAllRigsPending,
  getAllRigsSuccess: state.qc.status.getAllRigsSuccess,
  provisionRigPending: state.qc.status.provisionRigPending,
  provisionRigSuccess: state.qc.status.provisionRigSuccess,
});

const mapDispatchToProps = dispatch => ({
  getAllRigs: () => dispatch(
    actions.getAllRigsRequest()
  ),
  provisionRig: (deviceType, name) => dispatch(
    actions.provisionRigRequest(deviceType, name)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rigs);
