import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError } from '../utils';
import { actions } from '../../reducers/auth';

export default function* getAllUsers(action) {
  try {
    const response = yield call(API, {
      method: methods.GET,
      path: 'users',
    });
    const { users } = response;
    yield put(actions.getAllUsersSuccess(users));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
