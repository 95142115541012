import React, { Component } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
  You can find more info on 'react-toastify' from here:
  https://github.com/fkhadra/react-toastify
*/

const StyledContainer = styled(ToastContainer).attrs({
  autoClose: 5000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: true,
  newestOnTop: false,
  pauseOnVisibilityChange: true,
  position: 'bottom-right',
  rtl: false,
})`
  .toastStyles {
    min-height: 0;
    padding: 0;
  }

  .toastBodyStyles {
    margin: 0;
    padding: 0.5em 1em;
  }

  .Toastify__close-button {
    display: none;
  }
`;

class ToastManager extends Component {
  render() {
    return <StyledContainer />;
  }
}

export default ToastManager;
