import { connect } from 'react-redux';

import Login from './Login';
import { actions } from '../../reducers/auth';

const mapStateToProps = state => ({
  loginPending: state.auth.status.loginPending,
  loginSuccess: state.auth.status.loginSuccess,
});

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(
    actions.loginRequest(username, password)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
