import React, { Component } from 'react';

import Button from '../../shared/Button';
import Table from '../../shared/Table';

import ProvisionUserModal from './ProvisionUserModal';
import UpdateUserPasswordModal from './UpdateUserPasswordModal';
import DeleteUserModal from './DeleteUserModal';

const tableHeaders = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'createdAt',
    label: 'Created',
    format: 'timestampRel',
  },
  {
    key: 'lastLogin',
    label: 'Last Login',
    format: 'timestampRel',
  },
];

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProvisionUserModal: false,
      showUpdateUserPasswordModal: false,
      updatePasswordUsername: '',
      showDeleteUserModal: false,
      deleteUsername: '',
    };
  }

  componentDidMount() {
    if (!this.props.getAllUsersPending) {
      this.props.getAllUsers();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.provisionUserPending
      && !this.props.provisionUserPending
      && this.props.provisionUserSuccess) {
      this.setState({ showProvisionUserModal: false });
    } else if (prevProps.deleteUserPending
      && !this.props.deleteUserPending
      && this.props.deleteUserSuccess) {
      this.setState({
        showDeleteUserModal: false,
        deleteUsername: '',
      });
    } else if (prevProps.updateUserPasswordPending
      && !this.props.updateUserPasswordPending
      && this.props.updateUserPasswordSuccess) {
      this.setState({
        showUpdateUserPasswordModal: false,
        updatePasswordUsername: '',
      });
    }
  }

  getTableActions() {
    const { username } = this.props;
    return [
      (entry) => (
        <Button primary
                onClick={() => this.setState({
                  showUpdateUserPasswordModal: true,
                  updatePasswordUsername: entry.username,
                })}>
          Change Password
        </Button>
      ),
      (entry) => (
        <Button primary
                disabled={entry.username === username}
                onClick={() => this.setState({
                  showDeleteUserModal: true,
                  deleteUsername: entry.username,
                })}>
          Delete
        </Button>
      ),
    ];
  }

  renderTable() {
    if (this.props.getAllUsersPending) {
      return (
        <p>Loading users...</p>
      );
    }
    return (
      <>
        <Button primary
                onClick={() => this.setState({ showProvisionUserModal: true })}>
          Add User
        </Button>
        <Table headers={tableHeaders}
               actions={this.getTableActions()}
               data={this.props.users} />
      </>
    );
  }

  renderModals() {
    if (this.state.showProvisionUserModal) {
      return (
        <ProvisionUserModal provisionUserPending={this.props.provisionUserPending}
                            onClose={() => this.setState({ showProvisionUserModal: false })}
                            onSubmit={(username, password) => this.props.provisionUser(username, password)} />
      );
    }
    if (this.state.showUpdateUserPasswordModal) {
      return (
        <UpdateUserPasswordModal username={this.state.updatePasswordUsername}
                                 deleteUserPending={this.props.updateUserPasswordPending}
                                 onClose={() => this.setState({
                                   showUpdateUserPasswordModal: false,
                                   updatePasswordUsername: '',
                                 })}
                                 onSubmit={(password) => (
                                   this.props.updateUserPassword(this.state.updatePasswordUsername, password)
                                 )} />
      );
    }
    if (this.state.showDeleteUserModal) {
      return (
        <DeleteUserModal username={this.state.deleteUsername}
                         deleteUserPending={this.props.deleteUserPending}
                         onClose={() => this.setState({
                           showDeleteUserModal: false,
                            deleteUsername: '',
                         })}
                         onSubmit={() => this.props.deleteUser(this.state.deleteUsername)} />
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderModals()}
        <h1>Users</h1>
        {this.renderTable()}
      </>
    );
  }
}

export default Users;
