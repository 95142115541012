import React from 'react';

import Button from '../../shared/Button';

import { PageInner, StyledPage } from './styles';

const ErrorPage = (props) => (
  <StyledPage>
    <PageInner>
      <h1>Oops!</h1>
      <Button primary
              onClick={() => props.onAcknowledgeError()}>
        Reload
      </Button>
    </PageInner>
  </StyledPage>
);

export default ErrorPage;
