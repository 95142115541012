import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError } from '../utils';
import { actions } from '../../reducers/auth';

export default function* deleteUser(action) {
  try {
    const { username } = action.payload;
    yield call(API, {
      method: methods.DELETE,
      path: `users/${username}`,
    });
    yield put(actions.deleteUserSuccess(username));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
