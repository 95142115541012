import React from "react";
import { getRigColumnHeader, getValidationColumnHeader } from "./util";

export const getPrintHeadRunsTableHeaders = ({ rigs }) => [
  {
    key: 'serialNumber',
    label: <>Serial<br />Number</>,
  },
  {
    key: 'fwVersion',
    label: <>Factory FW<br />Version</>,
  },
  getRigColumnHeader(rigs),
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: <>Trials Before<br />QC Pass</>,
  },
  {
    key: 'runNumber',
    label: <>QC Trial<br/>Number</>,
  },
  {
    key: 'timestamp',
    label: 'Started At',
    format: 'timestamp',
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
    formatCell: entry => (entry.failureReason ? entry.message : ''),
  },
  getValidationColumnHeader('heatTightening', 'Heat Tightened Status'),
  getValidationColumnHeader('sensorValidation', 'Sensor Validation Status'),
];

export const getPrintHeadCSVColumns = () => [
  {
    key: 'serialNumber',
    label: 'Serial Number',
  },
  {
    key: 'fwVersion',
    label: 'Factory FW Version',
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  {
    key: 'rigId',
    label: 'Rig ID',
  },
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: 'Trials Before QC Pass',
  },
  {
    key: 'runNumber',
    label: 'QC Trial Number',
  },
  {
    key: 'timestamp',
    label: 'Started At'
  },
  {
    key: 'completedAt',
    label: 'Completed At'
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
  },
  {
    key: 'heatTightening',
    label: 'Heat Tightened Status',
  },
  {
    key: 'sensorValidation',
    label: 'Sensor Validation Status',
  },
];

export const getPrintHeadErrorTypes = () => ({
  // Special Use Case errors (100-)
  1000:	'Unexpected Aqua error',
  1001: 'Operator called failure',

  // Provsioning errors (101-)
  1011: 'Failed to properly initialize printhead',
  1012: 'Failed to provision printhead data',
  1013: 'Failed to calibrate encoder to default encoder resolution',

  // Sensor Validation errors (102-)
  1021: 'Filament switch did not read the correct value',
  1022: 'Encoder movement was detected when it was not expected',
  1023: 'Heatsink fan was not able to turn on',
  1024: 'Scroll wheel did not move the correct amount',
  1025: 'Extruder motor failed to extrude filament',
  1026: 'Part cooling fan was not able to turn on',

  // lol
  2343: 'toms a slug',
});
