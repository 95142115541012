import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError } from '../utils';
import { actions } from '../../reducers/auth';

export default function* updateUserPassword(action) {
  try {
    const { username, password } = action.payload;
    yield call(API, {
      method: methods.POST,
      path: `users/${username}`,
      body: {
        password,
      },
    });
    yield put(actions.updateUserPasswordSuccess(username));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
