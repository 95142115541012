import initialAuthState from './auth/initialState';
import _ from 'lodash';

const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY;

const filterStateForStorage = (state) => ({
  auth: {
    username: state.auth.username,
    token: state.auth.token,
  },
});

const load = () => {
  try {
    const serializedState = window.sessionStorage.getItem(STORAGE_KEY);
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
};

const save = (state) => {
  try {
    const serialized = JSON.stringify(state);
    window.sessionStorage.setItem(STORAGE_KEY, serialized);
  } catch (err) {
    // ignore write errors
  }
};

export const loadState = () => {
  // attempt to read SessionStorage
  const storedState = load() || { auth: {} };
  return {
    auth: {
      ...initialAuthState,
      ...storedState.auth,
    },
  };
}

export const saveState = (state) => {
  const filteredState = filterStateForStorage(state);
  save(filteredState);
}

export const observeStore = (store) => {
  let currentSessionState;
  const onChange = () => {
    const nextState = store.getState();
    const nextSessionState = filterStateForStorage(nextState);
    // avoid unnecessary writes
    if (!_.isEqual(nextSessionState, currentSessionState)) {
      currentSessionState = nextSessionState;
      saveState(nextState);
    }
  };
  const unsubscribe = store.subscribe(onChange);
  onChange(); // call once to populate currentSessionState
  return unsubscribe;
};
