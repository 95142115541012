import { combineReducers } from 'redux';

import auth from './auth';
import qc from './qc';

const reducer = combineReducers({
  auth,
  qc,
});

export default reducer;
