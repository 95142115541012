import React from 'react';

import { Overlay, Content } from './styles';

const Modal = (props) => (
  <Overlay>
    <Content>
      {props.children}
    </Content>
  </Overlay>
);

export default Modal;
