import React from 'react';

import HeaderBar from './HeaderBar.jsx';
import NavBar from './NavBar.jsx';
import { PageInner, StyledPage } from './styles';

const Page = (props) => (
  <StyledPage>
    {props.username ? <HeaderBar username={props.username} logout={props.logout} /> : null}
    <PageInner>
      {props.username ? <NavBar /> : null}
      {props.children}
    </PageInner>
  </StyledPage>
);

export default Page;
