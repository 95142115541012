import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { deviceTypesList } from '../../constants/qc';
import Routes from '../../router/routes';

import { Nav, NavItem } from './styles';

const NavBar = () => {
  return (
    <Nav>
      {_.map(deviceTypesList, (deviceType) => (
        <NavItem key={deviceType}>
          <Link to={Routes.toQCSummary(deviceType)}>
            {deviceType} QC
          </Link>
        </NavItem>
      ))}
      <NavItem>
        <Link to={Routes.toRigs()}>
          Rigs
        </Link>
      </NavItem>
      <NavItem>
        <Link to={Routes.toUsers()}>
          Users
        </Link>
      </NavItem>
    </Nav>
  );
};

export default NavBar;
