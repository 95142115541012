import React from 'react';
import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { ToastMessage } from '../shared/ToastManager';

export const getAuthToken = state => state.auth.token;

export const sleep = async (ms) => new Promise((fulfill) => {
  setTimeout(fulfill, ms);
});

export function* handleGenericError(action, error) {
  const errorType = action.type.replace('_REQUEST', '_FAILURE');
  const errorAction = {
    type: errorType,
    payload: {
      error,
    },
  };
  yield put(errorAction);

  const message = <ToastMessage type='error'>
    {error.message}
  </ToastMessage>;
  toast.error(message, {
    autoClose: 10000,
    className: 'toastStyles',
    bodyClassName: 'toastBodyStyles',
  });
}

export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const linkElement = document.createElement('a');
  linkElement.setAttribute('href', url);
  linkElement.setAttribute('download', filename);
  linkElement.setAttribute('tabindex', '-1');
  linkElement.style.display = 'none';
  linkElement.style.pointerEvents = 'none';
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};
