import { call, select } from 'redux-saga/effects';

import { getAuthToken } from './utils';

export const getAbsoluteUrl = path => `${process.env.REACT_APP_API_HOST}/${path}`;

export const getHeaders = (token, contentType = 'application/json', extraHeaders = {}) => {
  const commonHeaders = {
    'Content-Type': contentType,
  };
  if (token) {
    commonHeaders.Authorization = `Bearer ${token}`;
  }
  return {
    ...commonHeaders,
    ...extraHeaders,
  };
};

export const methods = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};

export const defaultApiCallParams = {
  method: methods.GET, // default to GET
  token: 'store', // "keyword" to pull user access token from store
  headers: {}, // should not include "common headers"
};

/**
 * Make a request to the API
 * @param {object} params Request parameters
 * @param {string} [params.method='GET'] Request method
 * @param {string} params.path Endpoint path, with parameters substituted
 * @param {string|null} [params.token='store'] Auth token to use for the request
 *   - if falsy, no token will be used
 *   - if 'store', user access token will be retrieved from the store and used
 * @param {any} [params.body] Request body
 * @param {AbortSignal} [params.signal] AbortSignal object to abort request
 */
function* api(params = {}) {
  const merged = {
    ...defaultApiCallParams,
    ...params,
  };
  if (!merged.path) {
    throw new Error('Endpoint path is required');
  }

  const contentType = 'application/json';
  const url = getAbsoluteUrl(merged.path);
  let token = null;
  if (merged.token) {
    if (merged.token === 'store') {
      // retrieve user's access token from the store
      token = yield select(getAuthToken);
    } else {
      // use token value directly from params
      ({ token } = merged);
    }
  }
  const response = yield call(fetch, url, {
    method: merged.method,
    headers: getHeaders(token, contentType, merged.headers),
    body: JSON.stringify(merged.body),
    signal: merged.signal,
  });
  if (response.ok) {
    // 200-299 response -- always JSON
    return yield call([response, 'json']);
  }
  if (response.status >= 300 && response.status <= 399) {
    // 300-399 response
    return yield call([response, 'text']);
  }
  // 400+ response
  let error;
  try {
    error = yield call([response, 'json']);
  } catch (err) {
    error = {
      status: 400,
      message: 'Unexpected response format',
    };
  }
  throw error;
}

export default api;
