import { connect } from 'react-redux';

import QC from './QC';
import { actions } from '../../reducers/qc';

const mapStateToProps = (state, existingProps) => {
  const { deviceType } = existingProps.match.params;
  return {
    deviceType,
    qc: state.qc.qc[deviceType],
    rigs: state.qc.rigs,
    getAllRigsPending: state.qc.status.getAllRigsPending,
    getAllRigsSuccess: state.qc.status.getAllRigsSuccess,
    getQCRunsForDeviceTypePending: state.qc.status.getQCRunsForDeviceTypePending,
    getQCRunsForDeviceTypeSuccess: state.qc.status.getQCRunsForDeviceTypeSuccess,
    generateSerialNumbersPending: state.qc.status.generateSerialNumbersPending,
    generateSerialNumbersSuccess: state.qc.status.generateSerialNumbersSuccess,
    generateSerialNumbersProgress: state.qc.status.generateSerialNumbersProgress,
    downloadCSVPending: state.qc.status.downloadCSVPending,
    downloadCSVSuccess: state.qc.status.downloadCSVSuccess,
  }
};

const mapDispatchToProps = dispatch => ({
  getAllRigs: () => dispatch(
    actions.getAllRigsRequest()
  ),
  getQCRunsForDeviceType: (deviceType) => dispatch(
    actions.getQCRunsForDeviceTypeRequest(deviceType)
  ),
  generateSerialNumbers: (deviceType, variant, count) => dispatch(
    actions.generateSerialNumbersRequest(deviceType, variant, count)
  ),
  downloadCSV: (filename, columns, filteredData) => dispatch(
    actions.downloadCSVRequest(filename, columns, filteredData)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(QC);
