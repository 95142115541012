import { connect } from 'react-redux';

import Page from './Page.jsx';
import _ErrorPage from './ErrorPage.jsx';
import _NotFoundPage from './NotFoundPage.jsx';

import { actions } from '../../reducers/auth';

const mapStateToProps = state => ({
  username: state.auth.username,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(
    actions.logout()
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);

export const ErrorPage = _ErrorPage;
export const NotFoundPage = _NotFoundPage;
