import React from 'react';
import _ from 'lodash';

import { StyledSelect } from './styles';

const Select = (props) => (
  <StyledSelect disabled={props.disabled}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}>
    {_.map(props.options, (option) => (typeof option === 'string' ? (
      <option key={option} value={option} >{option}</option>
    ) : (
      <option key={option.value} value={option.value} >{option.label}</option>
    )))}
  </StyledSelect>
);

export default Select;
