import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Routes from './routes';
import SoftRedirectRoute from './SoftRedirectRoute.jsx';

import ToastManager from '../shared/ToastManager';

import Page, { ErrorPage, NotFoundPage } from '../views/Page';
import Login from '../views/Login';

import Dashboard from '../views/Dashboard';
import Rigs from '../views/Rigs';
import Users from '../views/Users';
import QC from '../views/QC';

class RouterApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.setState({ hasError: true });
  }

  renderPrivateRoute(path, component, exact = true) {
    return <SoftRedirectRoute
      exact={exact}
      path={path}
      shouldRedirect={this.props.isAuthenticated}
      redirectComponent={Login}
      component={component} />;
  }

  renderNotFoundRoute() {
    if (!this.props.isAuthenticated) {
      return <Login />;
    }
    return <Route component={NotFoundPage} />;
  }

  onAcknowledgeError() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage onAcknowledgeError={() => this.onAcknowledgeError()} />;
    }
    return (
      <Router>
        <Page>
          <Switch>
            {this.renderPrivateRoute(Routes.toDashboard(), Dashboard)}
            {this.renderPrivateRoute(Routes.toRigs(), Rigs)}
            {this.renderPrivateRoute(Routes.toUsers(), Users)}
            {this.renderPrivateRoute(Routes.toQCSummary(), QC)}

            {/* 404 view */}
            {this.renderNotFoundRoute()}
          </Switch>
          <ToastManager />
        </Page>
      </Router>
    );
  }
}

export default RouterApp;
