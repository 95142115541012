import styled from 'styled-components';

import theme from '../../constants/theme';

export const StyledPage = styled.div`
`;

export const PageInner = styled.div`
  background-color: white;
  padding: 5em 1em;
  //max-width: 60em;
  //margin-left: auto;
  //margin-right: auto;
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${theme.colors.aqua};
`;

export const HeaderInner = styled.header`
  padding: 1em;
  //max-width: 60em;
  //margin-left: auto;
  //margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export const SiteTitle = styled.h1`
  margin: 0;
`;

export const Username = styled.h2`
  font-size: 1em;
  font-weight: 500;
  margin: 0 0.5em 0 0;
`;

export const Nav = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const NavItem = styled.li`
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
