import React, { Component } from 'react';
import _ from 'lodash';

import { formatTimestamp, relativeTimestamp } from '../../constants/timestamps';

import { StyledTable, Th, Td, SortArrow } from './styles';

/*

header metadata format:
[
  {
    key: string,
    label?: string,

    // either A:
    formatCell?: JSX => JSX
    formatCellTitle?: string => string
    textAlign?: string
    // or B:
    format?: string
  },
  ...
]

 */

const sortData = (data, sortKey, ascending) => _.orderBy(data, sortKey, [ascending ? 'asc' : 'desc']);

const builtInFormatters = {
  timestamp: {
    formatCell: cellContent => (cellContent ? formatTimestamp(cellContent) : ''),
    formatCellTitle: () => '',
    textAlign: 'left',
  },
  timestampRel: {
    formatCell: cellContent => (cellContent ? relativeTimestamp(cellContent) : ''),
    formatCellTitle: cellContent => (cellContent ? formatTimestamp(cellContent) : ''),
    textAlign: 'left',
  },
};

class Table extends Component {
  static defaultProps = {
    headers: [],
    actions: null,
    data: {},
    defaultSortKey: null,
    defaultSortAscending: true,
    userSortable: false,
    getRowKey: null,
  };

  constructor(props) {
    super(props);
    const sortKey = props.defaultSortKey || props.headers[0].key;
    const sortAscending = props.defaultSortAscending;
    this.state = {
      sortKey,
      sortAscending,
      sortedData: sortData(props.data, sortKey, sortAscending),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const sortedData = sortData(this.props.data, this.state.sortKey, this.state.sortAscending);
      this.setState({ sortedData });
    }
  }

  onHeaderClick(e, header) {
    if (!this.props.userSortable) return;
    const sortKey = header.key;
    let sortAscending;
    if (sortKey === this.state.sortKey) {
      // clicking on current sort column reverses sort order
      sortAscending = !this.state.sortAscending;
    } else {
      // changing sort column switches back to ascending
      sortAscending = true;
    }
    const sortedData = sortData(this.props.data, sortKey, sortAscending);
    this.setState({ sortedData, sortKey, sortAscending });
  }

  renderHeaderCell(header) {
    let textAlign = 'left';
    if (header.format) {
      textAlign = builtInFormatters[header.format].textAlign;
    } else if (header.textAlign) {
      ({ textAlign } = header);
    }
    return (
      <Th key={header.key}
          textAlign={textAlign}
          clickable={this.props.userSortable}
          onClick={e => this.onHeaderClick(e, header)}
      >
        {(this.props.userSortable && this.state.sortKey === header.key) && (
          <SortArrow ascending={this.state.sortAscending} />
        )}
        {header.label || header.key}
      </Th>
    );
  }

  renderTableHead() {
    return (
      <thead>
      <tr>
        {_.map(this.props.headers, header => this.renderHeaderCell(header))}
      </tr>
      </thead>
    );
  }

  renderDataCell(header, entry) {
    let cellContent = entry[header.key];
    let cellTitle = null;
    let textAlign = 'left';
    if (header.format) {
      cellContent = builtInFormatters[header.format].formatCell(entry[header.key]);
      cellTitle = builtInFormatters[header.format].formatCellTitle(entry[header.key]);
      textAlign = builtInFormatters[header.format].textAlign;
    } else {
      if (typeof header.formatCell === 'function') {
        cellContent = header.formatCell(entry);
      }
      if (typeof header.formatCellTitle === 'function') {
        cellTitle = header.formatCellTitle(entry);
      }
      if (header.textAlign) {
        ({ textAlign } = header);
      }
    }
    return (
      <Td key={header.key}
          title={cellTitle}
          textAlign={textAlign}>
        {cellContent}
      </Td>
    );
  }

  renderActionsCell(actions, entry) {
    return (
      <Td textAlign='center'>
        {_.map(actions, (action, index) => (
          <React.Fragment key={index}>
            {action(entry)}
          </React.Fragment>
        ))}
      </Td>
    )
  }

  renderDataRow(entry) {
    const { headers, actions } = this.props;
    const defaultColumnKey = this.props.defaultSortKey || headers[0].key;
    let key = entry[defaultColumnKey];
    if (typeof this.props.getRowKey === 'function') {
      key = this.props.getRowKey(entry);
    }
    return (
      <tr key={key}>
        {_.map(headers, header => this.renderDataCell(header, entry))}
        {actions ? this.renderActionsCell(actions, entry) : null}
      </tr>
    );
  }

  renderTableBody() {
    return (
      <tbody>
      {_.map(this.state.sortedData, entry => this.renderDataRow(entry))}
      </tbody>
    );
  }

  render() {
    return (
      <StyledTable>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </StyledTable>
    );
  }
}

export default Table;
