import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError } from '../utils';
import { actions } from '../../reducers/auth';

export default function* provisionUser(action) {
  try {
    const { username, password } = action.payload;

    const response = yield call(API, {
      method: methods.PUT,
      path: 'users',
      body: {
        username,
        password,
      },
    });
    const { createdAt } = response;
    yield put(actions.provisionUserSuccess({
      username,
      createdAt,
    }));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
