import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { downloadBlob, handleGenericError } from '../utils';
import { actions } from '../../reducers/qc';

export default function* provisionRig(action) {
  try {
    const { deviceType, name } = action.payload;

    const body = {};
    if (name) {
      body.name = name;
    }

    const response = yield call(API, {
      method: methods.PUT,
      path: `rigs/${deviceType}`,
      body,
    });
    const { token, ...rig } = response;

    const tokenBlob = new Blob([token], { type: 'text/plain' });
    const tokenFilename = `${rig.deviceType}-${rig.id}.txt`;
    downloadBlob(tokenBlob, tokenFilename);

    yield put(actions.provisionRigSuccess(rig));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
