import { getRigColumnHeader, getValidationColumnHeader } from "./util";

export const getMaterialPodRunsTableHeaders = ({ rigs }) => [
  {
    key: 'serialNumber',
    label: <>Serial<br />Number</>,
  },
  {
    key: 'fwVersion',
    label: <>Factory FW<br />Version</>,
  },
  getRigColumnHeader(rigs),
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: <>Trials Before<br />QC Pass</>,
  },
  {
    key: 'runNumber',
    label: <>QC Trial<br/>Number</>,
  },
  {
    key: 'timestamp',
    label: 'Started At',
    format: 'timestamp',
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
    formatCell: entry => (entry.failureReason ? entry.message : ''),
  },
  getValidationColumnHeader('hardwareValidation', 'Hardware Validation Status'),
  getValidationColumnHeader('auxiliarySensorValidation', 'Auxiliary Sensor Validation Status'),
  getValidationColumnHeader('motorSensorValidation', 'Motor Sensor Validation Status'),
  getValidationColumnHeader('mechanicalValidation', 'Mechanical Validation Status'),
];

export const getMaterialPodCSVColumns = () => [
  {
    key: 'serialNumber',
    label: 'Serial Number',
  },
  {
    key: 'fwVersion',
    label: 'Factory FW Version',
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  {
    key: 'rigId',
    label: 'Rig ID',
  },
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: 'Trials Before QC Pass',
  },
  {
    key: 'runNumber',
    label: 'QC Trial Number',
  },
  {
    key: 'timestamp',
    label: 'Started At'
  },
  {
    key: 'completedAt',
    label: 'Completed At'
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
  },
  {
    key: 'hardwareValidation',
    label: 'Hardware Validation Status',
  },
  {
    key: 'auxiliarySensorValidation',
    label: 'Auxiliary Sensor Validation Status',
  },
  {
    key: 'motorSensorValidation',
    label: 'Motor Sensor Validation Status',
  },
  {
    key: 'mechanicalValidation',
    label: 'Mechanical Validation Status',
  },
];

export const getMaterialPodErrorTypes = () => ({
  // * Material Pod Aqua
  // Special Use Case errors (120-)
  1200: 'Unexpected Aqua error',
  1201: 'Operator called failure',

  // Provsioning errors (121-)
  1211: 'Failed to provision Material Pod data',

  // Hardware Validation errors (122-)

  // Auxiliary Sensor Validation errors (123-)
  1231: 'Humidity and Temperature were not within expected value ranges',
  1232: 'Failed to turn on Red button light properly',
  1233: 'Failed to turn on Green button light properly',
  1234: 'Failed to turn on Blue button light properly',
  1235: 'Failed to detect button press event',

  // Motor Sensor Validation errors (124-)
  1241: 'RFID data was not of the correct format',
  1242: 'Written RFID data does not match expected result',
  1243: 'Ingoing switch did not read the expected value',
  1244: 'Outgoing switch did not read the expected value',
  1245: 'Operator failed to load filament into the substructure',
  1246: 'Encoder did not move the correct amount',

  // Mechanical Validation errors (125-)
  1251: 'Filament was not retracted by the same distance as it was driven to',
  1252: 'Stallguard failed to respool the filament to the spool properly',
});
