import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError } from '../utils';
import { actions } from '../../reducers/auth';

export default function* login(action) {
  try {
    const { username, password } = action.payload;

    const response = yield call(API, {
      method: methods.POST,
      path: 'users/login',
      token: null,
      body: {
        username,
        password,
      },
    });
    const { token } = response;
    yield put(actions.loginSuccess(username, token));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
