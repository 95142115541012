import { createStore, applyMiddleware } from 'redux';

import reducer from './reducer';
import { loadState, observeStore } from './localStorage';
import { sagas, runSagas } from '../sagas';

const persistedState = loadState();

const middleware = applyMiddleware(sagas);

const store = createStore(reducer, persistedState, middleware);

// write to local storage when relevant store data changes
observeStore(store);

// initialize redux-saga
runSagas();

export default store;
