import React from "react";
import { getRigColumnHeader } from "./util";

export const getP3RunsTableHeaders = ({ rigs }) => [
  {
    key: 'serialNumber',
    label: <>Serial<br />Number</>,
  },
  {
    key: 'fwVersion',
    label: <>Factory FW<br />Version</>,
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  getRigColumnHeader(rigs),
  {
    key: 'aquaFWVersion',
    label: <>Aqua FW<br />Version</>,
  },
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: <>Trials Before<br />QC Pass</>,
  },
  {
    key: 'minSpliceDiameter',
    label: <>Min Splice<br />Diameter</>,
  },
  {
    key: 'maxSpliceDiameter',
    label: <>Max Splice<br />Diameter</>,
  },
  {
    key: 'runNumber',
    label: <>QC Trial<br/>Number</>,
  },
  {
    key: 'timestamp',
    label: 'Started At',
    format: 'timestamp',
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
  },
  {
    key: 'ppm',
    label: 'PPM',
  },
  {
    key: 'spc',
    label: 'SPC',
  },
  {
    key: 'encoderCalibrationLength',
    label: 'Encoder Calibration Length',
  },
];

export const getP3CSVColumns = () => [
  {
    key: 'serialNumber',
    label: 'Serial Number',
  },
  {
    key: 'fwVersion',
    label: 'Factory FW Version',
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  {
    key: 'rigId',
    label: 'Rig ID',
  },
  {
    key: 'aquaFWVersion',
    label: 'Aqua FW Version',
  },
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: 'Trials Before QC Pass',
  },
  {
    key: 'minSpliceDiameter',
    label: 'Min Splice Diameter',
  },
  {
    key: 'maxSpliceDiameter',
    label: 'Max Splice Diameter',
  },
  {
    key: 'runNumber',
    label: 'QC Trial Number',
  },
  {
    key: 'timestamp',
    label: 'Started At'
  },
  {
    key: 'completedAt',
    label: 'Completed At'
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
  },
  {
    key: 'ppm',
    label: 'PPM',
  },
  {
    key: 'spc',
    label: 'SPC',
  },
  {
    key: 'encoderCalibrationLength',
    label: 'Encoder Calibration Length',
  },
];
