const types = {
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH/LOGIN_FAILURE',

  LOGOUT: 'AUTH/LOGOUT',

  GET_ALL_USERS_REQUEST: 'AUTH/GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS: 'AUTH/GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE: 'AUTH/GET_ALL_USERS_FAILURE',

  PROVISION_USER_REQUEST: 'AUTH/PROVISION_USER_REQUEST',
  PROVISION_USER_SUCCESS: 'AUTH/PROVISION_USER_SUCCESS',
  PROVISION_USER_FAILURE: 'AUTH/PROVISION_USER_FAILURE',

  UPDATE_USER_PASSWORD_REQUEST: 'AUTH/UPDATE_USER_PASSWORD_REQUEST',
  UPDATE_USER_PASSWORD_SUCCESS: 'AUTH/UPDATE_USER_PASSWORD_SUCCESS',
  UPDATE_USER_PASSWORD_FAILURE: 'AUTH/UPDATE_USER_PASSWORD_FAILURE',

  DELETE_USER_REQUEST: 'AUTH/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'AUTH/DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'AUTH/DELETE_USER_FAILURE',
};

export default types;
