import React, { Component } from 'react';

import { deviceVariants } from '../../constants/qc';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Modal from '../../shared/Modal';
import ProgressBar from '../../shared/ProgressBar';
import Select from '../../shared/Select';

import { GenerateSerialNumbersForm } from './styles';

class GenerateSerialNumbersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variant: deviceVariants[props.deviceType][0],
      count: 1,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onVariantChange = this.onVariantChange.bind(this);
    this.onCountChange = this.onCountChange.bind(this);
  }

  onVariantChange(value) {
    this.setState({ variant: value });
  }

  onCountChange(value) {
    this.setState({ count: Number.parseInt(value) });
  }

  onSubmit(e) {
    e.preventDefault();
    const { variant, count } = this.state;
    this.props.onSubmit(variant, count);
  }

  renderProgressStatus() {
    if (!this.props.generateSerialNumbersPending) return null;
    const { current, total } = this.props.generateSerialNumbersProgress;
    return <ProgressBar value={current}
                        max={total}>
      {current}/{total}
    </ProgressBar>;
  }

  render() {
    const disabled = this.props.generateSerialNumbersPending;
    const { variant, count } = this.state;
    return (
      <Modal>
        <h2>Generate Serial Numbers</h2>
        <GenerateSerialNumbersForm onSubmit={this.onSubmit}>
          <Select disabled={disabled}
                  value={variant}
                  onChange={this.onVariantChange}
                  options={deviceVariants[this.props.deviceType]}
          />
          <Input type='number'
                 placeholder='Count'
                 disabled={disabled}
                 value={count}
                 onChange={this.onCountChange}
          />
          {this.renderProgressStatus()}
          <div>
            <Button disabled={disabled}
                    onClick={() => this.props.onClose()}>
              Cancel
            </Button>
            <Button primary
                    type='submit'
                    disabled={disabled}>
              Generate
            </Button>
          </div>
        </GenerateSerialNumbersForm>
      </Modal>
    );
  }
}

export default GenerateSerialNumbersModal;
