import _ from 'lodash';

import authTypes from './types';
import authActions from './actions';
import initialState from './initialState';

export const types = authTypes;
export const actions = authActions;

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loginPending: true,
          loginSuccess: false,
        },
      };
    }
    case types.LOGIN_SUCCESS: {
      const { username, token } = payload;
      return {
        ...state,
        username,
        token,
        status: {
          ...state.status,
          loginPending: false,
          loginSuccess: true,
        },
      };
    }
    case types.LOGIN_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          loginPending: false,
          loginSuccess: false,
        },
      };
    }
    case types.LOGOUT: {
      return initialState;
    }
    case types.GET_ALL_USERS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getAllUsersPending: true,
          getAllUsersSuccess: false,
        },
      };
    }
    case types.GET_ALL_USERS_SUCCESS: {
      const { users } = payload;
      return {
        ...state,
        users,
        status: {
          ...state.status,
          getAllUsersPending: false,
          getAllUsersSuccess: true,
        },
      };
    }
    case types.GET_ALL_USERS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getAllUsersPending: false,
          getAllUsersSuccess: false,
        },
      };
    }
    case types.PROVISION_USER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          provisionUserPending: true,
          provisionUserSuccess: false,
        },
      };
    }
    case types.PROVISION_USER_SUCCESS: {
      const { user } = payload;
      return {
        ...state,
        users: {
          ...state.users,
          [user.username]: user,
        },
        status: {
          ...state.status,
          provisionUserPending: false,
          provisionUserSuccess: true,
        },
      };
    }
    case types.PROVISION_USER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          provisionUserPending: false,
          provisionUserSuccess: false,
        },
      };
    }
    case types.UPDATE_USER_PASSWORD_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateUserPasswordPending: true,
          updateUserPasswordSuccess: false,
        },
      };
    }
    case types.UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          updateUserPasswordPending: false,
          updateUserPasswordSuccess: true,
        },
      };
    }
    case types.UPDATE_USER_PASSWORD_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateUserPasswordPending: false,
          updateUserPasswordSuccess: false,
        },
      };
    }
    case types.DELETE_USER_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteUserPending: true,
          deleteUserSuccess: false,
        },
      };
    }
    case types.DELETE_USER_SUCCESS: {
      const { username } = payload;
      const users = _.omit(state.users, username);
      return {
        ...state,
        users,
        status: {
          ...state.status,
          deleteUserPending: false,
          deleteUserSuccess: true,
        },
      };
    }
    case types.DELETE_USER_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          deleteUserPending: false,
          deleteUserSuccess: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
