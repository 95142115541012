import _ from "lodash";

export const getRigColumnHeader = (rigs) => ({
  key: 'rigId',
  label: 'Rig',
  formatCell: entry => {
    const rig = _.find(rigs, rig => rig.id === entry.rigId);
    return rig ? rig.name : entry.rigId;
  },
  formatCellTitle: entry => entry.rigId,
});

export const getValidationColumnHeader = (key, label) => ({
  key,
  label,
  formatCell: (entry) => {
    if (entry[key] === null) return 'Not started';
    return entry[key] ? 'Pass' : 'Fail';
  },
});
