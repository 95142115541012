import styled from 'styled-components';

export const GenerateSerialNumbersForm = styled.form`
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
`;

export const FilterCheckboxLabel = styled.label`
  margin-left: 0.5em;
`;
