import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';
import moment from 'moment';

import API, { methods } from '../API';

import { downloadBlob, handleGenericError } from '../utils';
import { actions } from '../../reducers/qc';

const MAX_SERIALS_PER_REQUEST = 100;

function* request(deviceType, variant, count) {
  const serialNumbers = [];
  let timestamp;
  let serialsRequested = 0;
  while (serialsRequested < count) {
    const thisRequestCount = Math.min(count - serialsRequested, MAX_SERIALS_PER_REQUEST);
    const response = yield call(API, {
      method: methods.PUT,
      path: `serial-numbers/${deviceType}`,
      body: {
        variant,
        count: thisRequestCount,
      },
    });
    serialsRequested += thisRequestCount;
    serialNumbers.push(...response.serialNumbers);
    if (!timestamp) {
      timestamp = response.timestamp;
    }
    yield put(actions.generateSerialNumbersProgress(serialsRequested));
  }
  return { serialNumbers, timestamp };
}

export default function* generateSerialNumbers(action) {
  try {
    const { deviceType, variant, count } = action.payload;

    const { serialNumbers, timestamp } = yield call(request, deviceType, variant, count);

    const filename = `${variant}_${moment(timestamp).format('YYYY-MM-DD_HH.mm.ss')}.txt`;
    const fileContent = new Blob(serialNumbers.map(sn => `${sn}\n`));
    downloadBlob(fileContent, filename);

    yield put(actions.generateSerialNumbersSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
