import types from './types';

const actions = {
  getAllRigsRequest: () => ({
    type: types.GET_ALL_RIGS_REQUEST,
    payload: {},
  }),
  getAllRigsSuccess: (rigs) => ({
    type: types.GET_ALL_RIGS_SUCCESS,
    payload: { rigs },
  }),
  getAllRigsFailure: error => ({
    type: types.GET_ALL_RIGS_FAILURE,
    payload: { error },
  }),
  provisionRigRequest: (deviceType, name) => ({
    type: types.PROVISION_RIG_REQUEST,
    payload: { deviceType, name },
  }),
  provisionRigSuccess: (rig) => ({
    type: types.PROVISION_RIG_SUCCESS,
    payload: { rig },
  }),
  provisionRigFailure: error => ({
    type: types.PROVISION_RIG_FAILURE,
    payload: { error },
  }),
  getQCRunsForDeviceTypeRequest: (deviceType) => ({
    type: types.GET_QC_RUNS_FOR_DEVICE_TYPE_REQUEST,
    payload: { deviceType },
  }),
  getQCRunsForDeviceTypeSuccess: (deviceType, runs) => ({
    type: types.GET_QC_RUNS_FOR_DEVICE_TYPE_SUCCESS,
    payload: { deviceType, runs },
  }),
  getQCRunsForDeviceTypeFailure: error => ({
    type: types.GET_QC_RUNS_FOR_DEVICE_TYPE_FAILURE,
    payload: { error },
  }),
  clearQCRuns: () => ({
    type: types.CLEAR_QC_RUNS,
    payload: {},
  }),
  generateSerialNumbersRequest: (deviceType, variant, count) => ({
    type: types.GENERATE_SERIAL_NUMBERS_REQUEST,
    payload: { deviceType, variant, count },
  }),
  generateSerialNumbersSuccess: () => ({
    type: types.GENERATE_SERIAL_NUMBERS_SUCCESS,
    payload: {},
  }),
  generateSerialNumbersFailure: error => ({
    type: types.GENERATE_SERIAL_NUMBERS_FAILURE,
    payload: { error },
  }),
  generateSerialNumbersProgress: (currentProgress) => ({
    type: types.GENERATE_SERIAL_NUMBERS_PROGRESS,
    payload: { currentProgress },
  }),
  downloadCSVRequest: (filename, columns, filteredData) => ({
    type: types.DOWNLOAD_CSV_REQUEST,
    payload: { filename, columns, filteredData },
  }),
  downloadCSVSuccess: () => ({
    type: types.DOWNLOAD_CSV_SUCCESS,
    payload: {},
  }),
  downloadCSVFailure: error => ({
    type: types.DOWNLOAD_CSV_FAILURE,
    payload: { error },
  }),
};

export default actions;
