import qcTypes from './types';
import qcActions from './actions';
import initialState from './initialState';

export const types = qcTypes;
export const actions = qcActions;

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_RIGS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getAllRigsPending: true,
          getAllRigsSuccess: false,
        },
      };
    }
    case types.GET_ALL_RIGS_SUCCESS: {
      const { rigs } = payload;
      return {
        ...state,
        rigs,
        status: {
          ...state.status,
          getAllRigsPending: false,
          getAllRigsSuccess: true,
        },
      };
    }
    case types.GET_ALL_RIGS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getAllRigsPending: false,
          getAllRigsSuccess: false,
        },
      };
    }
    case types.PROVISION_RIG_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          provisionRigPending: true,
          provisionRigSuccess: false,
        },
      };
    }
    case types.PROVISION_RIG_SUCCESS: {
      const { rig } = payload;
      return {
        ...state,
        rigs: {
          ...state.rigs,
          [rig.id]: rig,
        },
        status: {
          ...state.status,
          provisionRigPending: false,
          provisionRigSuccess: true,
        },
      };
    }
    case types.PROVISION_RIG_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          provisionRigPending: false,
          provisionRigSuccess: false,
        },
      };
    }
    case types.GET_QC_RUNS_FOR_DEVICE_TYPE_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          getQCRunsForDeviceTypePending: true,
          getQCRunsForDeviceTypeSuccess: false,
        },
      };
    }
    case types.GET_QC_RUNS_FOR_DEVICE_TYPE_SUCCESS: {
      const { deviceType, runs } = payload;
      return {
        ...state,
        qc: {
          ...state.qc,
          [deviceType]: {
            ...state.qc[deviceType],
            runs,
          },
        },
        status: {
          ...state.status,
          getQCRunsForDeviceTypePending: false,
          getQCRunsForDeviceTypeSuccess: true,
        },
      };
    }
    case types.GET_QC_RUNS_FOR_DEVICE_TYPE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          getQCRunsForDeviceTypePending: false,
          getQCRunsForDeviceTypeSuccess: false,
        },
      };
    }
    case types.GENERATE_SERIAL_NUMBERS_REQUEST: {
      const { count } = payload;
      return {
        ...state,
        status: {
          ...state.status,
          generateSerialNumbersPending: true,
          generateSerialNumbersSuccess: false,
          generateSerialNumbersProgress: {
            current: 0,
            total: count,
          },
        },
      };
    }
    case types.GENERATE_SERIAL_NUMBERS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          generateSerialNumbersPending: false,
          generateSerialNumbersSuccess: true,
          generateSerialNumbersProgress: initialState.status.generateSerialNumbersProgress,
        },
      };
    }
    case types.GENERATE_SERIAL_NUMBERS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          generateSerialNumbersPending: false,
          generateSerialNumbersSuccess: false,
          generateSerialNumbersProgress: initialState.status.generateSerialNumbersProgress,
        },
      };
    }
    case types.GENERATE_SERIAL_NUMBERS_PROGRESS: {
      const { currentProgress } = payload;
      return {
        ...state,
        status: {
          ...state.status,
          generateSerialNumbersProgress: {
            ...state.status.generateSerialNumbersProgress,
            current: currentProgress,
          },
        },
      };
    }
    case types.DOWNLOAD_CSV_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          downloadCSVPending: true,
          downloadCSVSuccess: false,
        },
      };
    }
    case types.DOWNLOAD_CSV_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          downloadCSVPending: false,
          downloadCSVSuccess: true,
        },
      };
    }
    case types.DOWNLOAD_CSV_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          downloadCSVPending: false,
          downloadCSVSuccess: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
