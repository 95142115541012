const types = {
  GET_ALL_RIGS_REQUEST: 'QC/GET_ALL_RIGS_REQUEST',
  GET_ALL_RIGS_SUCCESS: 'QC/GET_ALL_RIGS_SUCCESS',
  GET_ALL_RIGS_FAILURE: 'QC/GET_ALL_RIGS_FAILURE',

  PROVISION_RIG_REQUEST: 'QC/PROVISION_RIG_REQUEST',
  PROVISION_RIG_SUCCESS: 'QC/PROVISION_RIG_SUCCESS',
  PROVISION_RIG_FAILURE: 'QC/PROVISION_RIG_FAILURE',

  GET_QC_RUNS_FOR_DEVICE_TYPE_REQUEST: 'QC/GET_QC_RUNS_FOR_DEVICE_TYPE_REQUEST',
  GET_QC_RUNS_FOR_DEVICE_TYPE_SUCCESS: 'QC/GET_QC_RUNS_FOR_DEVICE_TYPE_SUCCESS',
  GET_QC_RUNS_FOR_DEVICE_TYPE_FAILURE: 'QC/GET_QC_RUNS_FOR_DEVICE_TYPE_FAILURE',

  CLEAR_QC_RUNS: 'QC/CLEAR_QC_RUNS',

  GENERATE_SERIAL_NUMBERS_REQUEST: 'QC/GENERATE_SERIAL_NUMBERS_REQUEST',
  GENERATE_SERIAL_NUMBERS_SUCCESS: 'QC/GENERATE_SERIAL_NUMBERS_SUCCESS',
  GENERATE_SERIAL_NUMBERS_FAILURE: 'QC/GENERATE_SERIAL_NUMBERS_FAILURE',
  GENERATE_SERIAL_NUMBERS_PROGRESS: 'QC/GENERATE_SERIAL_NUMBERS_PROGRESS',

  DOWNLOAD_CSV_REQUEST: 'QC/DOWNLOAD_CSV_REQUEST',
  DOWNLOAD_CSV_SUCCESS: 'QC/DOWNLOAD_CSV_SUCCESS',
  DOWNLOAD_CSV_FAILURE: 'QC/DOWNLOAD_CSV_FAILURE',
};

export default types;
