import React, { Component } from 'react';

import Button from '../../shared/Button';
import Modal from '../../shared/Modal';
import Input from '../../shared/Input';

import { ProvisionUserForm } from './styles';

class ProvisionUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onUsernameInput = this.onUsernameInput.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
  }

  onUsernameInput(value) {
    this.setState({ username: value });
  }

  onPasswordInput(value) {
    this.setState({ password: value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.onSubmit(username, password);
  }

  render() {
    const disabled = this.props.provisionUserPending;
    const { username, password } = this.state;
    return (
      <Modal>
        <h2>Add User</h2>
        <ProvisionUserForm onSubmit={this.onSubmit}>
          <Input type='text'
                 placeholder='Username'
                 disabled={disabled}
                 value={username}
                 onChange={this.onUsernameInput}
          />
          <Input type='password'
                 placeholder='Password'
                 disabled={disabled}
                 value={password}
                 onChange={this.onPasswordInput}
          />
          <div>
            <Button disabled={disabled}
                    onClick={() => this.props.onClose()}>
              Cancel
            </Button>
            <Button primary
                    type='submit'
                    disabled={disabled}>
              Create
            </Button>
          </div>
        </ProvisionUserForm>
      </Modal>
    );
  }
}

export default ProvisionUserModal;
