import styled, { css } from 'styled-components';

import theme from '../../constants/theme';

export const StyledTable = styled.table`
  border-collapse: collapse;
`;

const cellStyles = css`
  padding: 0.2em 0.5em;
  border: solid 1px black;
  text-align: ${props => (props.textAlign || 'left')};
`;

export const Th = styled.th`
  ${cellStyles};
  ${props => props.clickable && css`
    cursor: pointer;
  `}
`;

export const Td = styled.td`
  ${cellStyles};
`;

export const SortArrow = styled.span`
  display: inline-block;
  line-height: 0;
  margin-right: 0.2em;
  margin-bottom: 0.2em;
  &::before {
    content: '${props => (props.ascending ? '↑' : '↓')}';
    background-color: ${theme.colors.aqua};
    padding: 0.1em 0.3em 0.2em;
    border-radius: 0.2em;
    color: white;
  }
`;
