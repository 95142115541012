import { all, takeLatest } from 'redux-saga/effects';

import { types } from '../../reducers/qc';

// import each saga
import getAllRigs from './get-all-rigs';
import provisionRig from './provision-rig';
import getQCRunsForDeviceType from './get-qc-runs-for-device-type';
import generateSerialNumbers from './generate-serial-numbers';
import downloadCSV from './download-csv';

export default function* authSaga() {
  yield all([
    takeLatest(types.GET_ALL_RIGS_REQUEST, getAllRigs),
    takeLatest(types.PROVISION_RIG_REQUEST, provisionRig),
    takeLatest(types.GET_QC_RUNS_FOR_DEVICE_TYPE_REQUEST, getQCRunsForDeviceType),
    takeLatest(types.GENERATE_SERIAL_NUMBERS_REQUEST, generateSerialNumbers),
    takeLatest(types.DOWNLOAD_CSV_REQUEST, downloadCSV),
  ]);
}
