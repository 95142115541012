import React, { Component } from 'react';

import Button from '../../shared/Button';
import Table from '../../shared/Table';

import ProvisionRigModal from './ProvisionRigModal.jsx';

const tableHeaders = [
  {
    key: 'id',
    label: 'Rig ID',
  },
  {
    key: 'deviceType',
    label: 'Device Type',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'createdAt',
    label: 'Created',
    format: 'timestamp',
  },
];

class Rigs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProvisionRigModal: false,
    };
  }

  componentDidMount() {
    if (!this.props.getAllRigsPending) {
      this.props.getAllRigs();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.provisionRigPending
      && !this.props.provisionRigPending
      && this.props.provisionRigSuccess) {
      this.setState({ showProvisionRigModal: false });
    }
  }

  renderTable() {
    if (this.props.getAllRigsPending) {
      return (
        <p>Loading rigs...</p>
      );
    }
    return (
      <>
        <Button primary
                onClick={() => this.setState({ showProvisionRigModal: true })}>
          Provision Rig
        </Button>
        <Table headers={tableHeaders}
               data={this.props.rigs} />
      </>
    );
  }

  renderModals() {
    if (this.state.showProvisionRigModal) {
      return (
        <ProvisionRigModal provisionRigPending={this.props.provisionRigPending}
                            onClose={() => this.setState({ showProvisionRigModal: false })}
                            onSubmit={(deviceType, name) => this.props.provisionRig(deviceType, name)} />
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderModals()}
        <h1>Rigs</h1>
        {this.renderTable()}
      </>
    );
  }
}

export default Rigs;
