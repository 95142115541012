import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

import { downloadBlob, handleGenericError } from '../utils';
import { actions } from '../../reducers/qc';

const escapeStringForCSV = (str) => {
  if (typeof str === 'string' && (str.includes(',') || str.includes('"'))) {
    return `"${str.replace(/"/g, '""')}"`;
  }
  return str;
};

const formatCSVRow = (row) => `${_.map(row, cell => escapeStringForCSV(cell)).join(',')}\n`;

const formatCSV = (columns, data) => {
  const rows = [
    // header row
    formatCSVRow(_.map(columns, ({ label }) => label)),
  ];

  // data rows
  _.forEachRight(data, (row) => {
    rows.push(formatCSVRow(_.map(columns, ({ key }) => row[key])));
  });
  return rows.join('');
};

export default function* downloadCSV(action) {
  try {
    const { filename, columns, filteredData } = action.payload;
    const sortedData = _.orderBy(filteredData, 'timestamp', ['asc']);
    const fileContent = new Blob([formatCSV(columns, sortedData)], { type: 'text/csv' });
    downloadBlob(fileContent, filename);
    yield put(actions.downloadCSVSuccess());
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
