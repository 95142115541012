import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError, sleep } from '../utils';
import { actions } from '../../reducers/qc';

function* request(deviceType) {
  let lastEvaluatedKey;
  const runs = [];
  do {
    const queryString = lastEvaluatedKey ? `?exclusiveStartKey=${encodeURIComponent(lastEvaluatedKey)}` : '';
    const response = yield call(API, {
      method: methods.GET,
      path: `qc/${deviceType}${queryString}`,
    });
    runs.push(...response.runs);
    lastEvaluatedKey = response.lastEvaluatedKey;
  } while (lastEvaluatedKey);
  return runs;
}

const formatRuns = async (runs) => {
  // iterate in ascending order (oldest to newest)
  const serialNumberTotalRunCount = new Map();
  const serialNumberCurrentRunCount = new Map();
  for (let i = 0; i < runs.length; i++) {
    if (i % 1000 === 0) {
      await sleep(1);
    }

    const qcEntry = runs[i];

    // QC status string
    if (qcEntry.completedAt) {
      qcEntry.result = qcEntry.failureReason || 'PASS';
    } else {
      qcEntry.result = '(incomplete)';
    }

    // numbering QC runs -- "global" counter that never resets
    if (serialNumberTotalRunCount.has(qcEntry.serialNumber)) {
      qcEntry.runNumber = serialNumberTotalRunCount.get(qcEntry.serialNumber) + 1;
      serialNumberTotalRunCount.set(qcEntry.serialNumber, qcEntry.runNumber)
    } else {
      qcEntry.runNumber = 1;
      serialNumberTotalRunCount.set(qcEntry.serialNumber, 1);
    }

    // QC runs before passing -- "local" counter that resets if a passed unit re-enters QC
    if (!serialNumberCurrentRunCount.has(qcEntry.serialNumber)) {
      serialNumberCurrentRunCount.set(qcEntry.serialNumber, 0);
    }
    const currentRunCount = serialNumberCurrentRunCount.get(qcEntry.serialNumber);
    if (qcEntry.completedAt && !qcEntry.failureReason) {
      // QC run passed
      qcEntry.runsBeforePass = currentRunCount + 1;
      serialNumberCurrentRunCount.set(qcEntry.serialNumber, 0);
    } else {
      // QC run failed/is in progress/was cancelled
      serialNumberCurrentRunCount.set(qcEntry.serialNumber, currentRunCount + 1);
    }
  }
  serialNumberTotalRunCount.clear();
  serialNumberCurrentRunCount.clear();

  // iterate in descending order (newest to oldest)
  const serialNumbersSeen = new Set();
  for (let i = runs.length - 1; i >= 0; i--) {
    if (i % 1000 === 0) {
      await sleep(1);
    }

    const qcEntry = runs[i];

    if (!serialNumbersSeen.has(qcEntry.serialNumber)) {
      serialNumbersSeen.add(qcEntry.serialNumber);
      if (qcEntry.completedAt && !qcEntry.failureReason) {
        qcEntry.status = 'Passed QC';
      } else {
        qcEntry.status = 'In QC';
      }
    }
  }
};

export default function* getQCRunsForDeviceType(action) {
  try {
    const { deviceType } = action.payload;
    if (deviceType === null) {
      yield put(actions.clearQCRuns());
      return;
    }
    const runs = yield call(request, deviceType);
    yield call(formatRuns, runs);
    yield put(actions.getQCRunsForDeviceTypeSuccess(deviceType, runs));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
