import types from './types';

const actions = {
  loginRequest: (username, password) => ({
    type: types.LOGIN_REQUEST,
    payload: { username, password },
  }),
  loginSuccess: (username, token) => ({
    type: types.LOGIN_SUCCESS,
    payload: { username, token },
  }),
  loginFailure: error => ({
    type: types.LOGIN_FAILURE,
    payload: { error },
  }),
  logout: () => ({
    type: types.LOGOUT,
    payload: {},
  }),
  getAllUsersRequest: () => ({
    type: types.GET_ALL_USERS_REQUEST,
    payload: {},
  }),
  getAllUsersSuccess: (users) => ({
    type: types.GET_ALL_USERS_SUCCESS,
    payload: { users },
  }),
  getAllUsersFailure: error => ({
    type: types.GET_ALL_USERS_FAILURE,
    payload: { error },
  }),
  provisionUserRequest: (username, password) => ({
    type: types.PROVISION_USER_REQUEST,
    payload: { username, password },
  }),
  provisionUserSuccess: (user) => ({
    type: types.PROVISION_USER_SUCCESS,
    payload: { user },
  }),
  provisionUserFailure: error => ({
    type: types.PROVISION_USER_FAILURE,
    payload: { error },
  }),
  updateUserPasswordRequest: (username, password) => ({
    type: types.UPDATE_USER_PASSWORD_REQUEST,
    payload: { username, password },
  }),
  updateUserPasswordSuccess: (username) => ({
    type: types.UPDATE_USER_PASSWORD_SUCCESS,
    payload: { username },
  }),
  updateUserPasswordFailure: error => ({
    type: types.UPDATE_USER_PASSWORD_FAILURE,
    payload: { error },
  }),
  deleteUserRequest: (username) => ({
    type: types.DELETE_USER_REQUEST,
    payload: { username },
  }),
  deleteUserSuccess: (username) => ({
    type: types.DELETE_USER_SUCCESS,
    payload: { username },
  }),
  deleteUserFailure: error => ({
    type: types.DELETE_USER_FAILURE,
    payload: { error },
  }),
};

export default actions;
