import styled from 'styled-components';

export const LoginForm = styled.form`
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  max-width: 15em;
  margin-left: auto;
  margin-right: auto;
`;
