import React from "react";
import { getRigColumnHeader } from "./util";

export const getDefaultRunsTableHeaders = ({ rigs }) => [
  {
    key: 'serialNumber',
    label: <>Serial<br />Number</>,
  },
  {
    key: 'fwVersion',
    label: <>Factory FW<br />Version</>,
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  getRigColumnHeader(rigs),
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: <>Trials Before<br />QC Pass</>,
  },
  {
    key: 'runNumber',
    label: <>QC Trial<br/>Number</>,
  },
  {
    key: 'timestamp',
    label: 'Started At',
    format: 'timestamp',
  },
  {
    key: 'completedAt',
    label: 'Completed At',
    format: 'timestamp',
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
  },
];

export const getDefaultCSVColumns = () => [
  {
    key: 'serialNumber',
    label: 'Serial Number',
  },
  {
    key: 'fwVersion',
    label: 'Factory FW Version',
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  {
    key: 'rigId',
    label: 'Rig ID',
  },
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: 'Trials Before QC Pass',
  },
  {
    key: 'runNumber',
    label: 'QC Trial Number',
  },
  {
    key: 'timestamp',
    label: 'Started At'
  },
  {
    key: 'completedAt',
    label: 'Completed At'
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
  },
];
