import React, { Component } from 'react';

import { ToastMessageContainer } from './toastMessage.styles';

class ToastMessage extends Component {
  static defaultProps = {
    type: 'success',
    message: '',
  };

  render() {
    return <ToastMessageContainer>
      {this.props.children}
    </ToastMessageContainer>;
  }
}

export default ToastMessage;
