import moment from 'moment';
import _ from 'lodash';

const getEmptySummaryData = (timeframe) => ({
  timeframe,
  inQC: 0,
  passedQC: 0,
  total: 0,
});

export const filterQCRuns = (runs, batchesToExclude, variantsToExclude, hideOldRuns) => {
  // need to ensure runs are sorted chronologically, newest first
  const runsChronological = _.orderBy(runs, 'timestamp', ['desc']);
  const serialNumbersSeen = new Set();
  return _.filter(runsChronological, (run) => {
    if (batchesToExclude.has(run.batch)) return false;
    if (variantsToExclude.size > 0 && variantsToExclude.has(run.serialNumber.split('-').pop())) return false;
    if (hideOldRuns) {
      if (serialNumbersSeen.has(run.serialNumber)) return false;
      serialNumbersSeen.add(run.serialNumber);
    }
    return true;
  });
};

export const getQCBatches = (runs) => {
  const batches = new Set();
  _.forEach(runs, (qcEntry) => {
    batches.add(qcEntry.batch);
  });
  return _.sortBy([...batches], _.identity);
};

export const getQCSummary = (runs) => {
  const serialNumbersSeen = new Set();
  const today = moment.utc().startOf('day');
  const oneWeekAgo = moment.utc(today).subtract(7, 'days');
  const twoWeeksAgo = moment.utc(today).subtract(14, 'days');
  const threeWeeksAgo = moment.utc(today).subtract(21, 'days');
  const fourWeeksAgo = moment.utc(today).subtract(28, 'days');
  const fiveWeeksAgo = moment.utc(today).subtract(35, 'days');
  const summary = {
    total: getEmptySummaryData('Total'),
    0: getEmptySummaryData('0 weeks ago (last 7 days)'),
    '-1': getEmptySummaryData('1 week ago'),
    '-2': getEmptySummaryData('2 weeks ago'),
    '-3': getEmptySummaryData('3 weeks ago'),
    '-4': getEmptySummaryData('4 weeks ago'),
  };
  const errorSummary = {};

  _.forEach(runs, (qcEntry) => {
    const { serialNumber } = qcEntry;
    const qcComplete = !!qcEntry.completedAt;
    const qcError = qcEntry.failureReason || '';

    // summary data that is interested in all QC runs
    if (qcComplete && qcError) {
      if (errorSummary[qcError]) {
        errorSummary[qcError]++;
      } else {
        errorSummary[qcError] = 1;
      }
    }

    // summary data that is only interested in the latest status of each unit
    if (serialNumbersSeen.has(serialNumber)) return;
    serialNumbersSeen.add(serialNumber);
    const timestamp = moment.utc(qcEntry.timestamp);
    const summaryKey = (qcComplete && !qcError) ? 'passedQC' : 'inQC';
    let timeSeriesKey;
    if (timestamp.isSameOrAfter(fiveWeeksAgo)) {
      if (timestamp.isSameOrAfter(fourWeeksAgo)) {
        if (timestamp.isSameOrAfter(threeWeeksAgo)) {
          if (timestamp.isSameOrAfter(twoWeeksAgo)) {
            if (timestamp.isSameOrAfter(oneWeekAgo)) {
              timeSeriesKey = '0';
            } else {
              timeSeriesKey = '-1';
            }
          } else {
            timeSeriesKey = '-2';
          }
        } else {
          timeSeriesKey = '-3';
        }
      } else {
        timeSeriesKey = '-4';
      }
    }
    summary.total[summaryKey]++;
    if (timeSeriesKey) {
      summary[timeSeriesKey][summaryKey]++;
    }
  });
  _.keys(summary).forEach((timeSeriesKey) => {
    summary[timeSeriesKey].total = summary[timeSeriesKey].passedQC + summary[timeSeriesKey].inQC;
  });

  return {
    summary,
    errorSummary,
  };
};
