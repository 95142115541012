import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { deviceTypes, deviceVariants } from '../../constants/qc';

import Table from '../../shared/Table';
import Button from '../../shared/Button';
import { Th, Td } from '../../shared/Table/styles';

import GenerateSerialNumbersModal from './GenerateSerialNumbersModal.jsx';
import { FilterCheckboxLabel } from './styles';
import { filterQCRuns, getQCBatches, getQCSummary } from './utils';
import { getP3CSVColumns, getP3RunsTableHeaders } from "./tables/p3";
import { getElementCSVColumns, getElementErrorTypes, getElementRunsTableHeaders } from "./tables/element";
import { getPrintHeadCSVColumns, getPrintHeadErrorTypes, getPrintHeadRunsTableHeaders } from "./tables/printHead";
import { getDefaultCSVColumns, getDefaultRunsTableHeaders } from "./tables/default";
import { getMaterialPodCSVColumns, getMaterialPodErrorTypes, getMaterialPodRunsTableHeaders } from "./tables/materialPod";

const summaryTableHeaders = [
  {
    key: 'timeframe',
    label: 'Timeframe',
  },
  {
    key: 'inQC',
    label: 'In QC',
  },
  {
    key: 'passedQC',
    label: 'Passed QC',
  },
  {
    key: 'total',
    label: 'Total',
  },
];

const errorSummaryTableHeaders = [
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'count',
    label: 'Occurrences',
  },
];

class QC extends Component {
  constructor(props) {
    super(props);
    const { summary, errorSummary } = getQCSummary(props.qc.runs);
    const batches = getQCBatches(props.qc.runs);
    this.state = {
      showGenerateSerialNumbersModal: false,
      filteredRuns: props.qc.runs,
      summary,
      errorSummary,
      batches,
      hideBatches: new Set(),
      variants: deviceVariants[props.deviceType],
      hideVariants: new Set(),
      hideOldRuns: false,
    };
    this.downloadCSV = this.downloadCSV.bind(this);
  }

  componentDidMount() {
    const { deviceType } = this.props;
    if (!this.props.getAllRigsPending) {
      this.props.getAllRigs();
    }
    if (!this.props.getQCRunsForDeviceTypePending) {
      this.props.getQCRunsForDeviceType(deviceType);
    }
  }

  componentWillUnmount() {
    this.props.getQCRunsForDeviceType(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.deviceType !== prevProps.deviceType) {
      const { summary, errorSummary } = getQCSummary(this.props.qc.runs);
      const batches = getQCBatches(this.props.qc.runs);
      this.setState({
        showGenerateSerialNumbersModal: false,
        filteredRuns: this.props.qc.runs,
        summary,
        errorSummary,
        batches,
        hideBatches: new Set(),
        variants: deviceVariants[this.props.deviceType],
        hideVariants: new Set(),
        hideOldRuns: false,
      }, () => {
        this.props.getQCRunsForDeviceType(this.props.deviceType);
      });
      return;
    }
    if (prevProps.generateSerialNumbersPending
      && !this.props.generateSerialNumbersPending
      && this.props.generateSerialNumbersSuccess) {
      this.setState({ showGenerateSerialNumbersModal: false });
    }
    if (this.props.qc !== prevProps.qc
      || this.state.hideBatches !== prevState.hideBatches
      || this.state.hideVariants !== prevState.hideVariants
      || this.state.hideOldRuns !== prevState.hideOldRuns
    ) {
      let { batches } = this.state;
      if (this.props.qc !== prevProps.qc) {
        batches = getQCBatches(this.props.qc.runs);
      }
      const filteredRuns = filterQCRuns(this.props.qc.runs, this.state.hideBatches, this.state.hideVariants, this.state.hideOldRuns);
      const { summary, errorSummary } = getQCSummary(filteredRuns);
      this.setState({
        filteredRuns,
        summary,
        errorSummary,
        batches,
      });
    }
  }

  renderFilters() {
    const { batches, variants } = this.state;
    return <table>
      <tbody>
      <tr>
        <Th textAlign='left'>Runs</Th>
        <Td title='Show all QC runs'>
          <input type='radio'
                 name='runs_filter'
                 id='runs_all'
                 checked={!this.state.hideOldRuns}
                 onChange={() => this.setState({ hideOldRuns: false })}
          />
          <FilterCheckboxLabel htmlFor='runs_all'>All</FilterCheckboxLabel>
        </Td>
        <Td title='Show only the latest run for each serial number'>
          <input type='radio'
                 name='runs_filter'
                 id='runs_latest'
                 checked={this.state.hideOldRuns}
                 onChange={() => this.setState({ hideOldRuns: true })}
          />
          <FilterCheckboxLabel htmlFor='runs_latest'>Latest</FilterCheckboxLabel>
        </Td>
      </tr>
      {batches.length <= 1 ? null : (
        <tr>
          <Th textAlign='left'>Batch</Th>
          {_.map(batches, batch => (
            <Td key={batch} textAlign='left'>
              <input type='checkbox'
                     id={`batch_${batch}`}
                     checked={!this.state.hideBatches.has(batch)}
                     onChange={(e) => {
                       const hideBatches = new Set([...this.state.hideBatches]);
                       if (e.target.checked) {
                        hideBatches.delete(batch);
                       } else {
                        hideBatches.add(batch);
                       }
                       this.setState({
                         hideBatches,
                       });
                     }}
              />
              <FilterCheckboxLabel htmlFor={`batch_${batch}`}>
                {batch}
              </FilterCheckboxLabel>
            </Td>
          ))}
        </tr>
      )}
      {variants.length <= 1 ? null : (
        <tr>
          <Th textAlign='left'>Type</Th>
          {_.map(variants, variant => (
            <Td key={variant} textAlign='left'>
              <input type='checkbox'
                     id={`variant_${variant}`}
                     checked={!this.state.hideVariants.has(variant)}
                     onChange={(e) => {
                       const hideVariants = new Set([...this.state.hideVariants]);
                       if (e.target.checked) {
                         hideVariants.delete(variant);
                       } else {
                         hideVariants.add(variant);
                       }
                       this.setState({
                         hideVariants,
                       });
                     }}
              />
              <FilterCheckboxLabel htmlFor={`variant_${variant}`}>
                {variant}
              </FilterCheckboxLabel>
            </Td>
          ))}
        </tr>
      )}
      </tbody>
    </table>
  }

  renderSummaryTable() {
    if (this.props.getQCRunsForDeviceTypePending) {
      return (
        <p>Loading QC summary...</p>
      );
    }
    return (
      <Table headers={summaryTableHeaders}
             data={this.state.summary} />
    );
  }

  renderErrorSummaryTable() {
    if (this.props.getQCRunsForDeviceTypePending) {
      return (
        <p>Loading QC error summary...</p>
      );
    }
    const errorTypeLabels = this.getErrorTypeLabels();
    const headers = errorTypeLabels
      ? [
        ...errorSummaryTableHeaders,
        {
          key: 'message',
          label: 'Message',
        }
      ]
      : errorSummaryTableHeaders;
    const data = _.map(this.state.errorSummary, (count, type) => {
      if (errorTypeLabels) {
        return { type, count, message: errorTypeLabels[type] };
      }
      return { type, count };
    });
    return (
      <Table headers={headers} data={data} />
    );
  }

  getErrorTypeLabels() {
    switch (this.props.deviceType) {
      case deviceTypes.Element:
        return getElementErrorTypes();
      case deviceTypes.PrintHead:
        return getPrintHeadErrorTypes();
      case deviceTypes.MaterialPod:
        return getMaterialPodErrorTypes();
      default:
        return null;
    }
  }

  getRunsTableHeaders() {
    const props = { rigs: this.props.rigs };
    switch (this.props.deviceType) {
      case deviceTypes.Element:
        return getElementRunsTableHeaders(props);
      case deviceTypes.MaterialPod:
        return getMaterialPodRunsTableHeaders(props);
      case deviceTypes.PrintHead:
        return getPrintHeadRunsTableHeaders(props);
      case deviceTypes.P3:
        return getP3RunsTableHeaders(props);
      default:
        return getDefaultRunsTableHeaders(props);
    }
  }

  getCSVColumns() {
    switch (this.props.deviceType) {
      case deviceTypes.Element:
        return getElementCSVColumns();
      case deviceTypes.MaterialPod:
        return getMaterialPodCSVColumns();
      case deviceTypes.PrintHead:
        return getPrintHeadCSVColumns();
      case deviceTypes.P3:
        return getP3CSVColumns();
      default:
        return getDefaultCSVColumns();
    }
  }

  downloadCSV() {
    const { deviceType } = this.props;
    const { filteredRuns } = this.state;
    const now = moment().toISOString();
    const filename = `${deviceType}-${now}.csv`;
    this.props.downloadCSV(filename, this.getCSVColumns(), filteredRuns);
  }

  renderRunsTable() {
    if (this.props.getQCRunsForDeviceTypePending) {
      return (
        <p>Loading QC runs...</p>
      );
    }
    return (
      <>
        <Button primary
                disabled={this.props.downloadCSVPending}
                onClick={this.downloadCSV}>
          Download CSV
        </Button>
        <Table headers={this.getRunsTableHeaders()}
               defaultSortKey='timestamp'
               defaultSortAscending={false}
               userSortable
               getRowKey={(entry) => `${entry.serialNumber}-${entry.timestamp}`}
               data={this.state.filteredRuns} />
      </>
    );
  }

  renderModals() {
    if (this.state.showGenerateSerialNumbersModal) {
      return (
        <GenerateSerialNumbersModal deviceType={this.props.deviceType}
                                    generateSerialNumbersPending={this.props.generateSerialNumbersPending}
                                    generateSerialNumbersProgress={this.props.generateSerialNumbersProgress}
                                    onClose={() => this.setState({ showGenerateSerialNumbersModal: false })}
                                    onSubmit={(variant, count) => (
                                      this.props.generateSerialNumbers(this.props.deviceType, variant, count)
                                    )} />
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderModals()}
        <h1>{this.props.deviceType} QC</h1>
        <Button primary
                onClick={() => this.setState({ showGenerateSerialNumbersModal: true })}>
          Generate Serial Numbers
        </Button>
        <h2>Filter</h2>
        {this.renderFilters()}
        <p><strong>Note:</strong> all data below (including the summaries and CSV download) will reflect the filters applied.</p>
        <h2>Summary</h2>
        {this.renderSummaryTable()}
        <h2>Errors</h2>
        {this.renderErrorSummaryTable()}
        <h2>QC Runs</h2>
        {this.renderRunsTable()}
      </>
    );
  }
}

export default QC;
