import React, { Component } from 'react';

import Button from '../../shared/Button';
import Input from '../../shared/Input';

import { LoginForm } from './styles';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.onUsernameInput = this.onUsernameInput.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onUsernameInput(value) {
    this.setState({ username: value });
  }

  onPasswordInput(value) {
    this.setState({ password: value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  }

  render() {
    const disabled = this.props.loginPending;
    const { username, password } = this.state;
    return <LoginForm onSubmit={this.onSubmit}>
      <Input type='text'
             placeholder='Username'
             disabled={disabled}
             value={username}
             onChange={this.onUsernameInput}
      />
      <Input type='password'
             placeholder='Password'
             disabled={disabled}
             value={password}
             onChange={this.onPasswordInput}
      />
      <Button primary
              type='submit'
              disabled={disabled}>
        Login
      </Button>
    </LoginForm>
  }
}

export default Login;
