import { all, takeLatest } from 'redux-saga/effects';

import { types } from '../../reducers/auth';

// import each saga
import login from './login';
import getAllUsers from './get-all-users';
import provisionUser from './provision-user';
import updateUserPassword from './update-user-password';
import deleteUser from './delete-user';

export default function* authSaga() {
  yield all([
    takeLatest(types.LOGIN_REQUEST, login),
    takeLatest(types.GET_ALL_USERS_REQUEST, getAllUsers),
    takeLatest(types.PROVISION_USER_REQUEST, provisionUser),
    takeLatest(types.UPDATE_USER_PASSWORD_REQUEST, updateUserPassword),
    takeLatest(types.DELETE_USER_REQUEST, deleteUser),
  ]);
}
