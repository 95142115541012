import _ from 'lodash';

import { deviceTypes } from '../../constants/qc';

const initialDeviceTypeQCState = {
  summary: {},
  errorSummary: {},
  runs: {},
};

const initialState = {
  rigs: {},
  qc: _.mapValues(deviceTypes, () => initialDeviceTypeQCState),
  status: {
    getAllRigsPending: false,
    getAllRigsSuccess: false,
    provisionRigPending: false,
    provisionRigSuccess: false,
    getQCRunsForDeviceTypePending: false,
    getQCRunsForDeviceTypeSuccess: false,
    generateSerialNumbersPending: false,
    generateSerialNumbersSuccess: false,
    generateSerialNumbersProgress: {
      current: 0,
      total: 0,
    },
    downloadCSVPending: false,
    downloadCSVSuccess: false,
  },
};

export default initialState;
