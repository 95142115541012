export const deviceTypes = {
  P3: 'P3',
  Element: 'Element',
  PrintBed: 'PrintBed',
  HeatedBedAssembly: 'HeatedBedAssembly',
  PrintHead: 'PrintHead',
  MaterialPod: 'MaterialPod',
  ChamberHeater: 'ChamberHeater',
  CoolingModule: 'CoolingModule',
  Array: 'Array',
  ArrayEBox: 'ArrayEBox',
  TowerX: 'TowerX',
  StorageCart: 'StorageCart',
  EndEffector: 'EndEffector',
};

export const deviceTypesList = [
  deviceTypes.P3,
  deviceTypes.Element,
  deviceTypes.PrintBed,
  deviceTypes.HeatedBedAssembly,
  deviceTypes.PrintHead,
  deviceTypes.MaterialPod,
  deviceTypes.ChamberHeater,
  deviceTypes.CoolingModule,
  deviceTypes.Array,
  deviceTypes.ArrayEBox,
  deviceTypes.TowerX,
  deviceTypes.StorageCart,
  deviceTypes.EndEffector,
];

export const deviceVariants = {
  P3: ['P3', 'P3P'],
  Element: ['EL', 'ELA'],
  PrintBed: ['BT1', 'BT2'],
  HeatedBedAssembly: ['BA'],
  PrintHead: ['PHLT', 'PHHT'],
  MaterialPod: ['MP'],
  ChamberHeater: ['ELCH'],
  CoolingModule: ['ELCM'],
  Array: ['AR'],
  ArrayEBox: ['AREB'],
  TowerX: ['ARTX'],
  StorageCart: ['ARSC'],
  EndEffector: ['AREE'],
};
