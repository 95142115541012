import React, { Component } from 'react';

import { Container } from './styles';

class Dashboard extends Component {

  render() {
    return (
      <Container />
    );
  }
}

export default Dashboard;
