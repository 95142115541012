import React, { Component } from 'react';

import Button from '../../shared/Button';
import Modal from '../../shared/Modal';
import Input from '../../shared/Input';

import { ProvisionUserForm } from './styles';

class UpdateUserPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
  }

  onPasswordInput(value) {
    this.setState({ password: value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { password } = this.state;
    this.props.onSubmit(password);
  }

  render() {
    const disabled = this.props.updateUserPasswordPending;
    const { username } = this.props;
    const { password } = this.state;
    return (
      <Modal>
        <h2>Update Password</h2>
        <ProvisionUserForm onSubmit={this.onSubmit}>
          <Input type='text'
                 disabled
                 value={username}
          />
          <Input type='password'
                 placeholder='Password'
                 disabled={disabled}
                 value={password}
                 onChange={this.onPasswordInput}
          />
          <div>
            <Button disabled={disabled}
                    onClick={() => this.props.onClose()}>
              Cancel
            </Button>
            <Button primary
                    type='submit'
                    disabled={disabled}>
              Update
            </Button>
          </div>
        </ProvisionUserForm>
      </Modal>
    );
  }
}

export default UpdateUserPasswordModal;
