import React, { Component } from 'react';

import { StyledButton } from './styles';

class Button extends Component {
  static defaultProps = {
    type: 'button',
    primary: false,
    disabled: false,
    onClick: () => {},
  };
  render() {
    return (
      <StyledButton type={this.props.type}
                    primary={this.props.primary}
                    disabled={this.props.disabled}
                    onClick={e => this.props.onClick(e)}>
        {this.props.children}
      </StyledButton>
    );
  }
}

export default Button;
