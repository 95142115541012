import styled from 'styled-components';

export const ProvisionUserForm = styled.form`
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
`;

export const DeleteUserContent = styled.div`
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
`;
