import styled from 'styled-components';

import theme from '../../constants/theme';

export const StyledButton = styled.button`
  font-size: 1em;
  font-family: inherit;
  background-color: ${props => (props.primary ? theme.colors.aqua : 'white')};
  border: solid 1px ${props => (props.primary ? 'white' : theme.colors.aqua)};
  border-radius: 0.2em;
  color: ${props => (props.primary ? 'white' : theme.colors.aqua)};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
