import React from 'react';

import Button from '../../shared/Button';
import Modal from '../../shared/Modal';
import Input from '../../shared/Input';

import { DeleteUserContent } from './styles';

const DeleteUserModal = (props) => {
  const disabled = props.deleteUserPending;
  return (
    <Modal>
      <h2>Delete User</h2>
      <DeleteUserContent>
        <Input type='text'
               disabled
               value={props.username}
        />
        <div>
          <Button disabled={disabled}
                  onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button primary
                  disabled={disabled}
                  onClick={() => props.onSubmit()}>
            Delete
          </Button>
        </div>
      </DeleteUserContent>
    </Modal>
  );
};

export default DeleteUserModal;
