import React from "react";
import { getRigColumnHeader, getValidationColumnHeader } from "./util";

export const getElementRunsTableHeaders = ({ rigs }) => [
  {
    key: 'serialNumber',
    label: <>Serial<br />Number</>,
  },
  {
    key: 'fwVersion',
    label: <>Factory FW<br />Version</>,
  },
  getRigColumnHeader(rigs),
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: <>Trials Before<br />QC Pass</>,
  },
  {
    key: 'runNumber',
    label: <>QC Trial<br/>Number</>,
  },
  {
    key: 'timestamp',
    label: 'Started At',
    format: 'timestamp',
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
    formatCell: entry => (entry.failureReason ? entry.message : ''),
  },
  getValidationColumnHeader('beltTensioningPassed', 'Belt Tensioning Status'),
  getValidationColumnHeader('visualInspectionPassed', 'Visual Inspection Status'),
  getValidationColumnHeader('generalSensorValidationPassed', 'Sensor Validation Status'),
  getValidationColumnHeader('filamentPathPassed', 'Filament Path Validation Status'),
  getValidationColumnHeader('thermalValidationPassed', 'Thermal Validation Status'),
  getValidationColumnHeader('bedLevellingPassed', 'Bed Levelling Status'),
  getValidationColumnHeader('testPrintPassed', 'Test Printing Status'),
  {
    key: 'loadCellAMaxValue',
    label: 'Load Cell A Max',
  },
  {
    key: 'loadCellAMinValue',
    label: 'Load Cell A Min',
  },
  {
    key: 'loadCellBMaxValue',
    label: 'Load Cell B Max',
  },
  {
    key: 'loadCellBMinValue',
    label: 'Load Cell B Min',
  },
  {
    key: 'loadCellCMaxValue',
    label: 'Load Cell C Max',
  },
  {
    key: 'loadCellCMinValue',
    label: 'Load Cell C Min',
  },
  {
    key: 'loadCellDMaxValue',
    label: 'Load Cell D Max',
  },
  {
    key: 'loadCellDMinValue',
    label: 'Load Cell D Min',
  },
  {
    key: 'finalFlatness',
    label: 'Final Flatness',
  },
];

export const getElementCSVColumns = () => [
  {
    key: 'serialNumber',
    label: 'Serial Number',
  },
  {
    key: 'fwVersion',
    label: 'Factory FW Version',
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  {
    key: 'rigId',
    label: 'Rig ID',
  },
  {
    key: 'status',
    label: 'QC Status',
  },
  {
    key: 'runsBeforePass',
    label: 'Trials Before QC Pass',
  },
  {
    key: 'runNumber',
    label: 'QC Trial Number',
  },
  {
    key: 'timestamp',
    label: 'Started At'
  },
  {
    key: 'completedAt',
    label: 'Completed At'
  },
  {
    key: 'result',
    label: 'QC Result',
  },
  {
    key: 'failureInfo',
    label: 'Failure Info',
  },
  {
    key: 'beltTensioningPassed',
    label: 'Belt Tensioning Status',
  },
  {
    key: 'visualInspectionPassed',
    label: 'Visual Inspection Status',
  },
  {
    key: 'generalSensorValidationPassed',
    label: 'Sensor Validation Status',
  },
  {
    key: 'filamentPathPassed',
    label: 'Filament Path Validation Status',
  },
  {
    key: 'thermalValidationPassed',
    label: 'Thermal Validation Status',
  },
  {
    key: 'bedLevellingPassed',
    label: 'Bed Levelling Status',
  },
  {
    key: 'testPrintPassed',
    label: 'Test Printing Status',
  },
  {
    key: 'loadCellAMaxValue',
    label: 'Load Cell A Max',
  },
  {
    key: 'loadCellAMinValue',
    label: 'Load Cell A Min',
  },
  {
    key: 'loadCellBMaxValue',
    label: 'Load Cell B Max',
  },
  {
    key: 'loadCellBMinValue',
    label: 'Load Cell B Min',
  },
  {
    key: 'loadCellCMaxValue',
    label: 'Load Cell C Max',
  },
  {
    key: 'loadCellCMinValue',
    label: 'Load Cell C Min',
  },
  {
    key: 'loadCellDMaxValue',
    label: 'Load Cell D Max',
  },
  {
    key: 'loadCellDMinValue',
    label: 'Load Cell D Min',
  },
  {
    key: 'finalFlatness',
    label: 'Final Flatness',
  },
];

export const getElementErrorTypes = () => ({
  // Special Use Case errors (90-)
  900:	'Unexpected Aqua error',
  901: 'Operator called failure',

  // Bed Levelling errors (91-)
  911: 'Failed to complete XY homing properly',
  912: 'Failed to complete movement properly',
  913: 'Bed flatness exceeded acceptable tolerance range',
  914: 'Load cell sensitivity value exceeded minimum acceptable value',
  915: 'Load cell sensitivity value exceeded maximum acceptable value',

  // Test Printing errors (92-)
  921: 'Component failed to heat properly',
  922: 'Mesh level failed',
  923: 'Could not properly read printhead data',
  924: 'Error homing printer',
  925: 'Error printing test print',

  // Provisioning errors (93-)
  931: 'Could not properly provision printer with the serial number',
  932: 'Unable to update firmware',

  // Visual Inspection errors (94-)
  941: 'Chamber light is not operating correctly',
  942: 'Power lights are not operating correctly',
  943: 'Status lights are not operating correctly',

  // Thermal Validation errors (95-)
  951: 'Chamber thermistor is not reading the correct temperature',
  952: 'Part cooling fan was unable to turn on successfully',
  953: 'Nozzle thermistor is not reading the correct temperature',
  954: 'Nozzle heater did not heat up in time.',
  955: 'Bed thermistor is not reading the correct temperature',
  956: 'Bed heater did not heat up in time.',
  957: 'Chamber current sensor did not indicate the heater is on',
  958: 'Chamber fan sensor did not indicate the fan is on',

  // Sensor errors (96-)
  961: 'Door sensor value is incorrect',
  962: 'Transition Container value is incorrect',
  963: 'Hepa fan is not functioning properly',
  964: 'Usb is not mounted',
  965: 'Usb is not reading data properly',

  // Filament Path errors (97-)
  971: 'Could not properly home the cutter',
  972: 'The filament path failed validation',
});
