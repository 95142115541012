const initialState = {
  username: '',
  token: '',
  allUsers: {},
  status: {
    loginPending: false,
    loginSuccess: false,
    getAllUsersPending: false,
    getAllUsersSuccess: false,
    provisionUserPending: false,
    provisionUserSuccess: false,
    updateUserPasswordPending: false,
    updateUserPasswordSuccess: false,
    deleteUserPending: false,
    deleteUserSuccess: false,
  },
};

export default initialState;
