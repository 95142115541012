import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError } from '../utils';
import { actions } from '../../reducers/qc';

function* request() {
  let lastEvaluatedKey;
  const rigs = [];
  do {
    const queryString = lastEvaluatedKey ? `?exclusiveStartKey=${encodeURIComponent(lastEvaluatedKey)}` : '';
    const response = yield call(API, {
      method: methods.GET,
      path: `rigs${queryString}`,
    });
    rigs.push(...response.rigs);
    lastEvaluatedKey = response.lastEvaluatedKey;
  } while (lastEvaluatedKey);
  return rigs;
}

export default function* getAllRigs(action) {
  try {
    const rigs = yield call(request);
    yield put(actions.getAllRigsSuccess(rigs));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
