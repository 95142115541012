import React, { Component } from 'react';

import { StyledProgress } from './styles';

class ProgressBar extends Component {
  render() {
    return (
      <StyledProgress value={this.props.value}
                      max={this.props.max}>
        {this.props.children}
      </StyledProgress>
    );
  }
}

export default ProgressBar;
